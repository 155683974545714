<template>
  <div id="enterprises" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">EMPRESAS PAGADORAS</v-row>
      <v-row no-gutters justify="end" class="mr-12">
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <router-link to="/addEnterprise" id="nuevaempresa">
              <button class="breakSearch botonAmarillo">Alta de Empresa</button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="enterprises"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Empresas por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.Id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">{{ item.razonSocial }}</td>
                  <td class="text-sm-center">{{ item.rfc }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="advice = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Empresa</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" scrollable max-width="370px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de Empresa</v-card-title
        >
        <v-card-text style="height: 300px" id="info">
          <div class="form-group mt-5">
            <label>ID de Empresa:</label>
            {{ enterpriseSelect.id }}
          </div>
          <div class="form-group">
            <label>Razón Social:</label>
            {{ enterpriseSelect.razonSocial }}
          </div>
          <div class="form-group">
            <label>IMSS:</label>
            {{ enterpriseSelect.nss }}
          </div>
          <div class="form-group">
            <label>País:</label>
            {{ enterpriseSelect.pais }}
          </div>
          <div class="form-group">
            <label>C.P. :</label>
            {{ enterpriseSelect.codigoPostal }}
          </div>
          <div class="form-group">
            <label>Estado:</label>
            {{ state }}
          </div>
          <div class="form-group">
            <label>Municipio:</label>
            {{ municipality }}
          </div>
          <div class="form-group">
            <label>Colonia:</label>
            {{ enterpriseSelect.asentamientoId }}
          </div>
          <div class="form-group">
            <label>Calle:</label>
            {{ enterpriseSelect.calle }}
          </div>
          <div class="form-group">
            <label>N° Exterior:</label>
            {{ enterpriseSelect.numeroExterior }}
          </div>
          <div class="form-group">
            <label>N° Interior:</label>
            {{ enterpriseSelect.numeroInterior }}
          </div>

          <div class="form-group">
            <label>Logotipo:</label>
            <img :src="enterpriseSelect.logo" width="30%" height="30%" alt />
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      state: "",
      municipality: "",
      address: [],
      headers: [
        {
          text: "N°",
          align: "center",
          value: "id",
        },
        {
          text: "Razón social",
          align: "center",
          value: "razonSocial",
        },
        {
          text: "RFC",
          align: "center",
          value: "rfc",
        },
        {
          text: "Editar  /   Ver  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      enterprises: [],
      enterpriseSelect: [],
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      edit: false,
      vacio: false,
    };
  },
  methods: {
    editItem(item) {
      //console.log("Ingreso a editar empresa" + item.id);
      localStorage.enterpriseId = item.id;
      this.$router.push("/updateEnterprise");
    },
    temporal(item) {
      //console.log(item);
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar la Empresa " + item.razonSocial + " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/empresas/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.show = false;
          this.confirmation = true;
          this.listar();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = true;
          }
        });
    },
    viewItem(item) {
      //console.log("Ingreso a detalle" + item.id);
      this.enterpriseSelect = item;
      this.consultaDireccion();
      this.dialog = true;
    },
    consultaDireccion() {
      if (this.enterpriseSelect.codigoPostal.length == 5) {
        this.suburbs = [];
        this.show = true;
        axios
          .get(Server + "/direcciones/" + this.enterpriseSelect.codigoPostal, {
            headers: {
              Authorization: localStorage.token,
            },
          })
          .then((response) => {
            //console.log(response);
            this.address = response.data;
            this.state = this.address[0].estado;
            this.municipality = this.address[0].municipio;
            this.address.forEach((value, index) => {
              if (
                value.asentamientoId == this.enterpriseSelect.asentamientoId
              ) {
                this.enterpriseSelect.asentamientoId = value.colonia;
              }
            });
            //console.log(suburbs);
            this.show = false;
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      } else {
        console.log("Menor a 5");
      }
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
  },
};
</script>